<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>
        <div
          class="d-flex justify-content-between align-center"
          style="width: 100%"
        >
          <span>{{ $t("Promos") }}</span>
        </div>
      </v-card-title>

      <goDialogPromos
        v-model="showGoDialog"
        :reloadTable="getDataFromApi"
        :row="selectedRow"
        :importExcelChainTemplatePathName="importExcelChainTemplatePathName"
      />

      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.brandId"
                      v-bind:label="$t('brands.brand')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_brand"
                      :loading="isBrandSearching"
                      :items="filtered_brands"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeBrand"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                      required
                      :rules="requiredRules"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="selected?.promosPdfFile">
                  <v-col cols="12" sm="12" md="12" class="text-center">
                    <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="selected.promosPdfFile.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                    >
                      <template
                        v-if="selected.promosPdfFile.promosPdfFile"
                        #label
                      >
                        {{ selected.promosPdfFile.promosPdfFile.file }}
                      </template>
                    </v-file-input>
                    <v-btn
                      v-if="selected.promosPdfFile.promosPdfFile"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(selected.promosPdfFile.promosPdfFile)"
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ `${$t("Download")} ${$t("PDF File")}` }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_start"
                      v-model="date_menu_start"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startDate"
                          v-bind:label="$t('start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startDate"
                        no-title
                        @input="date_menu_start = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_start"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_end"
                      v-model="date_menu_end"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endDate"
                          v-bind:label="$t('end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endDate"
                        no-title
                        @input="date_menu_end = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_end"
                        @change="onChangeDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_start_order"
                      v-model="date_menu_start_order"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startOrderDate"
                          v-bind:label="$t('startOrderDate')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startOrderDate"
                        no-title
                        @input="date_menu_start_order = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_start_order"
                        @change="onChangeOrderDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu_end_order"
                      v-model="date_menu_end_order"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endOrderDate"
                          v-bind:label="$t('endOrderDate')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endOrderDate"
                        no-title
                        @input="date_menu_end_order = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        :reactive="reactive_end_order"
                        @change="onChangeOrderDates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.productIds"
                      v-bind:label="$t('Product')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_product"
                      :loading="isProductSearching"
                      :items="filtered_products"
                      hide-no-data
                      filled
                      multiple
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.materialId"
                      v-bind:label="$t('Material')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_material"
                      :loading="isMaterialSearching"
                      :items="filtered_materials"
                      hide-no-data
                      hide-selected
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.promosType"
                      v-bind:label="`${this.$t('Type')} Promo`"
                      :items="promosTypeList"
                      item-text="label"
                      item-value="value"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.place"
                      v-bind:label="$t('Place')"
                      :items="placeTypeList"
                      item-text="label"
                      item-value="value"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.price"
                      v-bind:label="$t('Price')"
                      type="number"
                      required
                      :rules="priceRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.folleto"
                      v-bind:label="$t('Folleto')"
                      :items="folletoTypeList"
                      item-text="label"
                      item-value="value"
                      filled
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.isSurveyResponse"
                      v-bind:label="`Respuesta Encuesta`"
                      :items="surveyResponseList"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(selected)"
                :loading="isSaving"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-if="copyItemId > 0" v-model="dialogCopy" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">Copy</v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="copyBrandIds"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brand"
                    :loading="isBrandSearching"
                    :items="filtered_brands"
                    hide-no-data
                    multiple
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeCopyDialog">{{
              $t("cancel")
            }}</v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="copyItemConfirm"
              :loading="isBtnCopying"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark @click="onItemEdit">
              {{ $t("newitem") }} </v-btn
            ><input
              ref="uploaderBrandpromos"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChangedBrandpromos"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import goDialogPromos from "./goDialogPromos.vue";

export default {
  name: "Promos",
  components: {
    goDialogPromos
  },
  data: function() {
    return {
      status_type: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ],
      promosTypeList: [],
      placeTypeList: [],
      folletoTypeList: [],
      promosStatusList: [],
      surveyResponseList: [],
      importExcelChainTemplatePathName: null,
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },
      priceRules: [
        // (v) => !!v || "Required",
        // (v) => (v && v >= 0) || "This field should be above 0",
        // (v) => (v && v < 1000) || "This field should not be above 1000",

        v => !v || (v >= 0 && v < 1000) || "This field should be from 0 to 1000"
      ],
      /*************** Import and Export Excel *************/
      isSelectingBrandpromos: false,
      selectedFileBrandpromos: null,
      defaultbuttonTextBrandpromos: "Import",

      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      copyItemId: -1,
      copyBrandIds: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** create or edit dialog options **********/
      showEdit: false,
      dialogCopy: false,
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],
      search_product: null,
      isProductSearching: false,
      filtered_products: [],
      search_material: null,
      isMaterialSearching: false,
      filtered_materials: [],

      isSaving: false,
      isBtnCopying: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,
      date_menu_start_order: false,
      date_menu_end_order: false,
      reactive_start_order: false,
      reactive_end_order: false,

      totaltableData: 0,
      tableData: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: [
        "brand",
        "description",
        "startDate",
        "endDate",
        "startOrderDate",
        "endOrderDate",
        "status",
        "product",
        "material",
        "promosType",
        "place",
        "price",
        "folleto",
        "isSurveyResponse"
      ],
      filterCriteria: {
        brand: {},
        description: {},
        startDate: {},
        endDate: {},
        startOrderDate: {},
        endOrderDate: {},
        status: {},
        product: {},
        material: {},
        promosType: {},
        place: {},
        price: {},
        folleto: {},
        isSurveyResponse: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.statusKey === "PREPARE") {
            return "table-body-cell-incidence";
          }
        }
      },
      showEmpty: false,
      showGoDialog: false,
      selectedRow: null
    };
  },
  watch: {
    dialogCopy(val) {
      val || this.closeCopyDialog();
    },
    async search_brand(val) {
      this.search_brand_list(val);
    },
    async search_product(val) {
      this.search_product_list(val);
    },
    async search_material(val) {
      this.search_material_list(val);
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "brandId",
          title: `ID ${this.$t("brands.brand")}`,
          field: "brandId",
          align: "left",
          width: 100,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "brand",
          title: `${this.$t("brands.brand")}`,
          field: "brand",
          align: "left",
          width: 150,
          sortBy: "",
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand && row.brand.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brand"]}
                    apiUrl={`brands/promos/getfilterlist`}
                    columnName={`brand`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brand"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brand")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["description"]}
                    apiUrl={`brands/promos/getfilterlist`}
                    columnName={`description`}
                    isFullText={false}
                    filterCriteria={this.filterCriteria["description"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "startDate",
          title: this.$t("start_date"),
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endDate",
          title: this.$t("end_date"),
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "startOrderDate",
          title: this.$t("startOrderDate"),
          field: "startOrderDate",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startOrderDate"]["from"]}
                    value={this.filterCriteria["startOrderDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startOrderDate"]["to"]}
                    value={this.filterCriteria["startOrderDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "startOrderDate")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endOrderDate",
          title: this.$t("endOrderDate"),
          field: "endOrderDate",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endOrderDate"]["from"]}
                    value={this.filterCriteria["endOrderDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endOrderDate"]["to"]}
                    value={this.filterCriteria["endOrderDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "endOrderDate")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: this.$t("salesforce.status"),
          field: "status",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.status}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["status"]}
                    dataList={this.promosStatusList}
                    filterCriteria={this.filterCriteria["status"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "promosPdfFile",
          title: this.$t("PDF File"),
          field: "promosPdfFile",
          align: "center",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row.promosPdfFile && (
                  <v-btn
                    color="primary"
                    dark
                    fab
                    on-click={() => this.downloadPDF(row.promosPdfFile)}
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                  </v-btn>
                )}
              </div>
            );
          }
        },
        {
          key: "product",
          title: `${this.$t("Product")}`,
          field: "product",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.productsLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["product"]}
                    apiUrl={`brands/promos/getfilterlist`}
                    columnName={`product`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["product"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "product")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "promosType",
          title: `${this.$t("Type")} Promo`,
          field: "promosType",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.promosTypeLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["promosType"]}
                    dataList={this.promosTypeList}
                    filterCriteria={this.filterCriteria["promosType"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "promosType")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "price",
          title: this.$t("Price"),
          field: "price",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["price"]["from"]}
                    value={this.filterCriteria["price"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="number"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["price"]["to"]}
                    value={this.filterCriteria["price"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "price")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "place",
          title: this.$t("Place"),
          field: "place",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.placeLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["place"]}
                    dataList={this.placeTypeList}
                    filterCriteria={this.filterCriteria["place"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "place")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "material",
          title: `${this.$t("Material")}`,
          field: "material",
          align: "left",
          width: 150,
          sortBy: "",
          fixed: "left",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.material && row.material.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["material"]}
                    apiUrl={`brands/promos/getfilterlist`}
                    columnName={`material`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["material"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "material")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "folleto",
          title: this.$t("Folleto"),
          field: "folleto",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.folleto}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["folleto"]}
                    dataList={this.folletoTypeList}
                    filterCriteria={this.filterCriteria["folleto"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "folleto")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "isSurveyResponse",
          title: `Respuesta Encuesta`,
          field: "isSurveyResponse",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.surveyResponseLabel}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["isSurveyResponse"]}
                    dataList={this.surveyResponseList}
                    filterCriteria={this.filterCriteria["isSurveyResponse"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "isSurveyResponse")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        },
        {
          key: "go",
          title: this.$t("chain"),
          field: "go",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        },
        {
          key: "copy",
          title: this.$t("copy"),
          field: "copy",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  dark
                  color="success darken-1"
                  text
                  on-click={() => this.onItemCopy(row.id)}
                >
                  <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    },
    posTableColumns() {
      return [
        {
          text: "IdPOS",
          value: "id",
          width: "25%",
          filter: value => {
            return this.activeFilters.id
              ? this.activeFilters.id.includes(value)
              : true;
          }
        },
        {
          text: "POS " + this.$t("brands.name"),
          value: "name",
          width: "50%",
          filter: value => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          }
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "25%",
          filter: value => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          }
        }
      ];
    }
  },
  methods: {
    async onItemGo(row) {
      await this.getDataFromApi();
      this.selectedRow = row;
      this.showGoDialog = true;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null && Object.keys(value).length > 0) {
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#data-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      try {
        let query_params_string = this.getQueryParams();
        let fetch_url = "brands/promos";
        if (query_params_string !== "") {
          fetch_url += "?" + query_params_string;
        }
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.promosTypeList = resp.promosTypeList;
        this.placeTypeList = resp.placeTypeList;
        this.folletoTypeList = resp.folletoTypeList;
        this.promosStatusList = resp.promosStatusList;
        this.surveyResponseList = resp.surveyResponseList;
        this.importExcelChainTemplatePathName =
          resp.importExcelChainTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "brands/promos/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "promos.xlsx");
      this.close();
    },
    onUploadExcelBrandpromosClick() {
      this.isSelectingBrandpromos = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingBrandpromos = false;
        },
        { once: false }
      );
      this.$refs.uploaderBrandpromos.click();
    },
    async onFileChangedBrandpromos(e) {
      this.selectedFileBrandpromos = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingBrandpromos = true;
        let data = new FormData();
        data.append("file", this.selectedFileBrandpromos);
        this.$refs.uploaderBrandpromos.value = "";
        try {
          const response = await ApiService.post(
            `brands/promos/uploadexcelBrandpromos/`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingBrandpromos = false;
          await this.getDataFromApi();
        } catch (error) {
          console.log(error);
          this.isSelectingBrandpromos = false;
        }
      }
    },
    async createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        status: _.get(item, "status"),
        description: _.get(item, "description"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        startOrderDate: _.get(item, "startOrderDate"),
        endOrderDate: _.get(item, "endOrderDate"),
        pdfFile: _.get(item, "pdfFile"),
        promosPdfFile: {
          file: null,
          promosPdfFile: _.get(item, "promosPdfFile")
        },
        productIds: _.get(item, "productIds"),
        product: _.get(item, "product"),
        materialId: _.get(item, "materialId"),
        material: _.get(item, "material"),

        promosType: _.get(item, "promosType"),
        place: _.get(item, "place"),
        price: _.get(item, "price"),
        folleto: _.get(item, "folleto"),
        isSurveyResponse: _.get(item, "isSurveyResponse"),
        brand_promos_products: _.get(item, "brand_promos_products")
      };
      await this.search_brand_list("");
      await this.search_product_list("", model.brandId);
      await this.search_material_list("", model.brandId);

      console.log(model);
      return model;
    },
    async onItemEdit(item) {
      console.log(item);
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    async onChangePDFFile(file) {
      this.selected.promosPdfFile.file = file;
    },
    async onChangeBrand() {
      if (this.selected?.brandId) {
        await this.search_product_list("", this.selected?.brandId);
        await this.search_material_list("", this.selected?.brandId);
        this.selected.productIds = null;
        this.selected.materialId = null;
      }
    },
    async search_brand_list(val) {
      let fetch_url = `brands/promos/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async search_product_list(val, brandId) {
      let fetch_url = `brands/promos/getfilterlist?column=selectableproducts&isFullText=true&filterValue=${val}`;
      if (brandId) {
        fetch_url += `&brandId=${brandId}`;
      }
      const resp = await ApiService.get(fetch_url);
      this.filtered_products = resp.data;
    },
    async search_material_list(val, brandId) {
      let fetch_url = `brands/promos/getfilterlist?column=selectablematerials&isFullText=true&filterValue=${val}`;
      if (brandId) {
        fetch_url += `&brandId=${brandId}`;
      }
      const resp = await ApiService.get(fetch_url);
      this.filtered_materials = resp.data;
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    async onChangeOrderDates() {
      if (this.selected.startOrderDate > this.selected.endOrderDate) {
        this.selected.endOrderDate = this.selected.startOrderDate;
      }
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["brand", "promosPdfFile"]);
        try {
          this.isSaving = true;
          let status = null;
          let promosPdfFile = item.promosPdfFile.file;
          if (promosPdfFile) {
            let { data } = await ApiService.uploadFile(promosPdfFile);
            body.pdfFile = data.id;
          } else if (!item.promosPdfFile.promosPdfFile) {
            logError("Se requiere un archivo PDF.");
            this.isSaving = false;
            return;
          }
          console.log(body);
          if (body.id) {
            status = await ApiService.put(`brands/promos/${body.id}`, body);
          } else {
            status = await ApiService.post(`brands/promos`, body);
          }
          logInfo("éxito");
          this.showEdit = false;
          this.isSaving = false;
          this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },
    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      if (this.deleteItemId > 0 && window.confirm(this.$t("confirmdelete"))) {
        ApiService.delete(`brands/promos/${this.deleteItemId}`).then(() => {
          logInfo("eliminada!");
          this.getDataFromApi();
        });
      }
    },
    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    async onItemCopy(id) {
      this.copyItemId = id;
      this.copyBrandIds = [];
      await this.search_brand_list("");
      this.dialogCopy = true;
    },
    closeCopyDialog() {
      this.dialogCopy = false;
      this.$nextTick(() => {
        this.copyItemId = -1;
      });
    },
    async copyItemConfirm() {
      if (this.copyItemId > 0 && this.copyBrandIds.length > 0) {
        this.isBtnCopying = true;
        try {
          let status = await ApiService.post(
            `brands/promos/copydata/${this.copyItemId}`,
            {
              brandIds: this.copyBrandIds
            }
          );
          this.isBtnCopying = false;
          this.closeCopyDialog();
          if (status) {
            const { success, message, data } = status;
            if (success) {
              logInfo("éxito");
              this.getDataFromApi();
            } else if (success === false) {
              logError(message);
            }
          }
        } catch (error) {
          this.closeCopyDialog();
          this.isBtnCopying = false;
        }
      } else {
        logError(this.$t("You must select one more brand."));
      }
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
