<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showGoDialog"
      v-if="current_row !== null && current_row !== undefined"
      max-width="90%"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <div class="title_contents">
              <div class="objective_content">
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("brands.description") }}:
                    </span>
                    <span>{{ current_row?.description }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-if="current_row?.brand">
                    <span class="title">{{ this.$t("start_date") }}: </span>
                    <span>{{ current_row?.startDate }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title">{{ this.$t("end_date") }}: </span>
                    <span>{{ current_row?.endDate }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12">
                <v-row class="mt-2 text-center">
                  <v-col cols="12" sm="12" md="12">
                    <span
                      style="font-size: 1rem; color: #000; font-weight: 500;"
                      >Chains per Assortment:</span
                    >
                    <v-btn
                      v-if="importExcelChainTemplatePathName"
                      class="mb-2 ml-2"
                      color="blue darken-1"
                      @click="
                        downloadExcelTemplateGlobal(
                          importExcelChainTemplatePathName
                        )
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      Plantilla Import
                    </v-btn>
                    <v-btn
                      color="success"
                      dark
                      class="mb-2 ml-2"
                      :loading="isSelectingChainsPerPromos"
                      @click="onUploadExcelChainsPerPromosClick"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ buttonTextChainsPerPromos }}
                    </v-btn>
                    <input
                      ref="uploaderChainsPerPromos"
                      class="d-none"
                      type="file"
                      accept=".xls,.xlsx"
                      @change="onFileChangedChainsPerPromos"
                    />
                    <v-btn
                      color="warning"
                      dark
                      class="mb-2 ml-2"
                      @click="downloadExcelChainsPerPromos"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Export
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="12">
                    <subChainsTable
                      :row="this.promoChainsArr"
                      :update_table="update_table"
                      :save="onClickSaveChainData"
                      :deleteItem="onClickDeleteChainData"
                      ref="subChainsTable"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import subChainsTable from "@/components/subChainsTable";

export default {
  name: "goDialogPromos",
  props: ["value", "row", "reloadTable", "importExcelChainTemplatePathName"],
  components: {
    subChainsTable,
  },
  data: function() {
    return {
      current_row: null,
      promoChainsArr: [],

      isProgressing: false,
      isUploadingExcelFile: false,
      selectedFile: null,
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      isSelectingChainsPerPromos: false,
      selectedFileIdposPerPromos: null,
      defaultbuttonTextChainsPerPromos: "Import",
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      console.log("this.current_row - ", this.current_row);
      if (newVal) {
        await this.init(newVal);
      }
    },
  },
  computed: {
    showGoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    buttonTextChainsPerPromos() {
      return this.isSelectingChainsPerPromos
        ? "Uploading..."
        : this.defaultbuttonTextChainsPerPromos;
    },
  },
  methods: {
    async onClickDeleteChainData(item) {
      console.log(item);
      if (window.confirm(this.$t("confirmdelete"))) {
        try {
          await ApiService.delete(`brands/promos/deletechains/${item.id}`);
          logInfo("Éxito !");
          await this.update_table();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    async onClickSaveChainData(editedItem, editedIndex) {
      if (editedIndex > -1) {
        let postData = {
          id: editedItem.id,
          status: editedItem.status,
        };
        try {
          await ApiService.post(`brands/promos/update_promos_chains`, postData);
          logInfo("Éxito !");
          await this.update_table();
          this.$refs.subChainsTable.close();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    async init() {
      if (this.current_row.brand_promos_chains?.length > 0) {
        this.promoChainsArr = this.current_row.brand_promos_chains.map((el) => {
          let push_item = { ...el };
          push_item.chainName = "-";
          push_item.subChainName = "-";
          push_item.sub2ChainName = "-";
          if (el.ChainPerPromos) push_item.chainName = el.ChainPerPromos.name;
          if (el.subChainPerPromos)
            push_item.subChainName = el.subChainPerPromos.name;
          if (el.sub2ChainPerPromos)
            push_item.sub2ChainName = el.sub2ChainPerPromos.name;
          return push_item;
        });
      } else {
        console.log("INIT CHAINS");
        this.promoChainsArr = [];
        const {
          isEmptyDefaultChainsOfBrand,
          isEmptyChainsPerPromos,
        } = await ApiService.get(
          `brands/promos/init_chains/${this.current_row.id}`
        );
        if (!isEmptyDefaultChainsOfBrand && !isEmptyChainsPerPromos) {
          this.updatedRowData(this.current_row);
        }
      }
    },

    async downloadExcelChainsPerPromos() {
      let fetch_url =
        "brands/promos/downloadexcel_chainsPerPromos/" + this.current_row.id;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob",
      });
      download(response, "chainsPerPromos.xlsx");
    },
    onUploadExcelChainsPerPromosClick() {
      this.isSelectingChainsPerPromos = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingChainsPerPromos = false;
        },
        { once: false }
      );
      this.$refs.uploaderChainsPerPromos.click();
    },
    async onFileChangedChainsPerPromos(e) {
      this.selectedFileIdposPerPromos = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelectingChainsPerPromos = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposPerPromos);
        this.$refs.uploaderChainsPerPromos.value = "";
        try {
          const response = await ApiService.post(
            `brands/promos/uploadexcelChainsPerPromos/` + this.current_row.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingChainsPerPromos = false;
          await this.reloadTable();
          await this.updatedRowData(this.current_row);
        } catch (error) {
          console.log(error);
          this.isSelectingChainsPerPromos = false;
        }
      }
    },
    async update_table() {
      await this.reloadTable();
      await this.updatedRowData(this.current_row);
    },
    async updatedRowData(row_data) {
      let fetch_url = "brands/promos/getitem/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.current_row = resp.data;
        this.init();
      } catch (error) {}
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style>
.objective_content .row span {
  color: #000;
  font-size: 1rem;
}
.objective_content .row span.title {
  font-weight: bold;
  font-size: 1rem !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
.title_contents {
  border: 3px solid #727272;
  border-radius: 5px;
}
.top_title h4 {
  margin: 0;
}
.top_title {
  padding: 15px 15px 15px 15px;
  border-bottom: 3px solid #727272;
}
.objective_content {
  padding: 15px;
}
</style>
